<template>
  <div class="500-page">
    <div class="500">
      <v-card color="white" width="100%" flat>
        <v-container class="py-10">
          <v-row>
            <!-- Sorry, something went wrong -->
            <v-col cols="12" lg="6">
              <h3 class="heading-h3">Sorry, something went wrong</h3>

              <p class="b-body-1 mt-4">
                Try reloading the page. we are working hard to fix for you as
                soon as possible. If you have any questions
                <a
                  href="mailto:support@lime24.co.za"
                  class="text-decoration-none font-weight-medium"
                  target="_blank"
                >
                  support@lime24.co.za
                </a>
              </p>

              <v-img
                alt="500"
                class="500 mt-2"
                contain
                src="@/assets/img/errors/500/500.svg"
                max-width="400"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";

export default {
  name: "LimeLoans500",

  data() {
    return {};
  },

  computed: {
    ...mapState({ errorHandler: "errorHandler" }),
  },

  created() {
    if (this.errorHandler.justGotErrors) {
      this.setJustGotErrors(false);
    } else {
      this.ping();
    }
  },

  mounted() {},

  methods: {
    ...mapActions({
      pingApi: "errorHandler/pingApi",
    }),

    ...mapMutations({
      setJustGotErrors: "errorHandler/setJustGotErrors",
    }),

    // ping
    async ping() {
      var pingRes = await this.pingApi();

      if (pingRes.status == 200) {
        this.$router.push(this.errorHandler.redirect);

        this.$helpers.resetErrorHandlerState();
      }
    },

    reloadPage() {
      window.location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
//
</style>
